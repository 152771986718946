a:hover{
    color: cornflowerblue;
}
.kendo__schedule-text{
    height: fit-content;
    text-align: center;
    font-family: var(--font-generic);
    color: white;
    font-size: 2.5rem;
    padding-top: 8rem;
    padding-bottom: 8rem;
}

.kendo__schedule-text-title{
    text-shadow: 2px 2px 9px rgba(0,0,0,0.6);
}
.kendo__schedule-text-sub{
    display: flex;
    flex-direction: row;
    justify-content: center;

    background: rgba(227, 200, 155, 0.75);
    margin-right: auto;
    margin-left: auto;
    border-radius: 20px;
    width: fit-content;
    max-width: 80vw;
    margin-top: 3rem;
}

.kendo__schedule-text-time{
    display: flex;
    flex-direction: column;
    margin-left: 3rem;
    margin-right: 3rem;
}

.kendo__schedule-text-location{
    font-size: 2rem;
    margin-top: 4rem;
    margin-bottom: 0rem;
}
.section__padding-schedule{
    scroll-margin-top: 6.4rem;
}

.parallax__schedule{
    /* The image used */
    background-image: url('../../assets/practice.jpg');
    
    /* Set a specific height */
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: beige;
}

@media screen and (max-width: 770px){
    .kendo__schedule-text{
        margin-top: 5rem;
        padding-bottom: 5rem;
    }
    .kendo__schedule-text-sub{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .kendo__schedule-text-time{
        display: flex;
        flex-direction: column;
        margin: 3rem 2rem;
    }
}