.title{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    color: whitesmoke;
}

.title-image{
    display: flex;
    object-fit: cover;
    width: 100%;
    max-height: 36rem;
}

.title-text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 8rem;
    display: flex;
    text-shadow: 2px 2px 9px rgba(0,0,0,0.6);
}

.mission-statement{
    flex-direction: column;
    text-align:center;
    font-size: 2rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
    color: white;
    background-color:rgb(3, 52, 110);
}

.mission-statement p{
    font-size: 2rem;
    margin-left: 10rem;
    margin-right: 10rem;
    justify-content: center;
}

.mission-statement-title{
    padding-bottom: 1rem;
}

.wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}