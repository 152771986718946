.kendo__navbar{
    top:0;
    width: 100%;
    display:flex;
    justify-content:space-around;
    align-items:center;
    z-index:11;
    background-image: linear-gradient(to right, rgb(3, 52, 110), cornflowerblue);
}

.kendo__navbar-links{   
    display:flex;
    justify-content:flex-end;
    align-items:center;
    padding-bottom:1rem;
    padding-right: 2rem;
}

.kendo__navbar-logo img{
    display:flex;
    width:6rem;
    height:5rem;
    margin-top:0.75rem;
    margin-bottom:0.75rem;
    padding-left: 1.5rem;
}

.kendo__navbar-links_container{
    display: flex;
    flex-direction: row;
}

.kendo__navbar-links_container p{
    color:#fff;
    font-family:var(--font-family);
    font-weight:500;
    font-size:1.5rem;
    line-height:0px;
    text-transform:capitalize;
    margin-right:1rem;
    margin-top:1rem;
    cursor:pointer;
}
.kendo__navbar-menu{
    display:none;
    position:fixed;
    align-self: flex-end;
    top:2rem;
    right:2rem;
}

.kendo__navbar-menu svg{
    cursor:pointer;
}

.kendo__navbar-menu_container{
    display:flex;
    align-content:flex-end;
    background:#c2a16c;
    position:fixed;
    top:6.5rem;
    right:0;
    padding-right:2.5rem;
    padding-left:2.5rem;
    border-radius:.25rem;
}

.kendo__navbar-menu_container p{
    background:#c2a16c;
    font-size:1.5rem;
    font-family:var(--font-family);
    color:#fff;
    margin:1rem 0;
    width:100%;
    align-self:center;
}

.kendo__navbar-menu_container-links-sign{
    display:none;
}

@media screen and (max-width: 1050px){
    .kendo__navbar{
        position:fixed;
        justify-content:space-between;
    }
    .kendo__navbar-links_container{
        display:none;
    }

    .kendo__navbar-menu{
        display:flex;
    }
}