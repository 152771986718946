.body {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-around;
}

.calendar-full{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 2rem;
}

.calendar-mobile{
    display: none;
}

@media screen and (max-width: 1050px){
    .body {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: space-around;
        padding-top: 5rem;
    }
    .calendar-full{
        display: none;
    }

    .calendar-mobile{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 2rem;
    }
}