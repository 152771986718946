@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
:root{
  --font-family:'Inter', sans-serif;
  --font-generic:'Roboto', sans-serif;
  
  --color-bg:#1387ce;
  --color-footer:#c2a16c;
  --color-blog:#042c54;
  --color-text:#81AFDD;
  --color-subtext:#FF8A71;
}