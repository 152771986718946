.contact{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.contact-button{
    color: #fff;
    font-size:  50px;
    margin-left: 3rem;
    margin-right: 3rem;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
}

@media screen and (max-width: 770px){
    .contact-button{
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: 0.5rem;
    }
}