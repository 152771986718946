.section__padding-email{
    scroll-margin-top: 6.4rem;
}

.kendo__email-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    width: auto;
    padding-top: 3em;
    padding-bottom: 3rem;
    background: #f6f6f6;
}

.kendo__email-container-pretext{
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    font-family: var(--font-family);	
}

.kendo__email-container-endtext{
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 0rem;
    margin-top: 2rem;
    font-family: var(--font-family);	
    text-align: center;
}

.kendo__email{
	width: 35rem;
    max-width: 100vw;
	margin: auto;
	/* white */
	background: #FFFFFF;
	/* shadow-1 */
	box-shadow: 0px 2px 4px rgba(9, 8, 66, 0.08), 0px 4px 24px rgba(9, 8, 66, 0.04);
	border-radius: 16px;

	display: flex;
    justify-content: center;  
    /* flex-flow: column; */ 
    flex-direction: column;  
    height: 30rem;
    padding: 15px;
    gap: 5px;


}

.kendo__email-title{
	display: flex;
	flex-direction: row;
	align-self: center;
	margin-bottom: 20px;
	font-family: var(--font-family);	
}

.kendo__email-field{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 1rem;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1.5rem;
    cursor: pointer;
}

input{
	font-family: var(--font-family);
	display: block;
    width: 90%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	align-self: center;
	margin-top: 0;
	margin-bottom: 1rem;
}

.kendo__email-submit{
	font-family: var(--font-family);
	border-color: transparent;
    border-radius: 30px;
    font-size: 1.345rem;
    font-weight: 600;
    padding: 10px 15px;
    background-color: #6aa2b8;
    color: #fff;
    max-width: 10em;
    position: relative;
    z-index: 10;
    margin: auto;
}

@media screen and (max-width: 1050px){
    .kendo__email{
        width: 80%;

    }
    .kendo__email-field{
        margin: 0 0.21rem;
    }
  }