*{
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body{
  background: var(--color-bg)
}

a{
  color: unset;
  text-decoration: none;
}

a:hover{
  text-decoration: underline;
}
.gradient__text{
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clop: text;
  -webkit-text-fill-color: transparent;
}

.scale-in-ver-top{
	-webkit-animation: scale-in-ver-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-ver-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.gradient__bg{

  /* ff 3.6+ */
  background:-moz-radial-gradient(circle at 0% 0%, rgba(0, 127, 208, 1) 0%, rgba(0, 100, 164, 1) 100%);

  /* safari 5.1+,chrome 10+ */
  background:-webkit-radial-gradient(circle at 0% 0%, rgba(0, 127, 208, 1) 0%, rgba(0, 100, 164, 1) 100%);

  /* opera 11.10+ */
  background:-o-radial-gradient(circle at 0% 0%, rgba(0, 127, 208, 1) 0%, rgba(0, 100, 164, 1) 100%);

  /* ie 10+ */
  background:-ms-radial-gradient(circle at 0% 0%, rgba(0, 127, 208, 1) 0%, rgba(0, 100, 164, 1) 100%);

  /* global 92%+ browsers support */
  background:radial-gradient(circle at 0% 0%, rgba(0, 127, 208, 1) 0%, rgba(0, 100, 164, 1) 100%);
 
 }
 
 @-webkit-keyframes scale-in-ver-top{
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-ver-top{
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}

.scale-out-ver-top{
	-webkit-animation: scale-out-ver-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: scale-out-ver-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}
